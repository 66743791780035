@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&display=swap);
* {
  box-sizing: border-box;
    margin: 0;
    padding:0%;
}
html{
  height:100%;
}
a{
  text-decoration: none;
  color: white;
}
ul{
  list-style-type: none;
}
body {
  background-color: #2a2a72;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

}

.user-form {
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
}

.user-form input {
  width: 100%;
  display: block;
  background-color: #4c2885;
  border: none;
  border-radius: 10px;
  color: #fff;
  padding: 1rem;
  margin-bottom: 2rem;
  margin-right: 15px;
  font-family: inherit;
  font-size: 1rem;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(0, 0, 0, 0.1);
}

.user-form input::-webkit-input-placeholder {
  color: #bbb;
}

.user-form input:-ms-input-placeholder {
  color: #bbb;
}

.user-form input::placeholder {
  color: #bbb;
}

.user-form input:focus {
  outline: none;
}

.card {
  max-width: 800px;
  background-color: #4c2885;
  border-radius: 20px;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 3rem;
  margin: 0 1.5rem;
}

.avatar {
  border-radius: 50%;
  border: 10px solid #2a2a72;
  height: 150px;
  width: 150px;
}

.user-info {
  color: #eee;
  margin-left: 2rem;
}
 p{
   max-width: 300px;
 }
.user-info h2 {
  margin-top: 0;
}

.user-info ul {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  padding: 0;
  max-width: 400px;
}

.user-info ul li {
  display: flex;
  align-items: center;
}

.user-info ul li strong {
  font-size: 0.9rem;
  margin-left: 0.5rem;
}
button{
  padding: 15px 25px;
  border: none;
  border-radius: 10px;
  background-color: #ffffff;
  color:#4c2885;
  margin-bottom: 2rem;
  cursor: pointer;
  font-weight: bold;
  font-size: medium;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
  0 15px 40px rgba(0, 0, 0, 0.1);
}
button:active{
  -webkit-transform: scale(0.90);
          transform: scale(0.90);
}
@media (max-width: 500px) {
  .card {
    flex-direction: column;
    align-items: center;
  }

  .user-form {
    max-width: 400px;
  }
}

#footer{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: #111;
  padding: 15px 23px;
  color: #fff;
  text-align: center;
  margin-top:auto;
}
#footer span a{
  color: white;
  text-decoration: none;
}
#footer span a:hover{
  /* text-decoration: underline; */
  cursor: pointer;
  color:  rgb(140, 20, 252);
}


